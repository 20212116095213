import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import { LoadingContext } from "../context/LoadingContext";
import ReactLoader from "../components/ReactLoader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HM from '../images/profile.png'
import PK from '../images/support.png'
import BY from '../images/buys.png'
import IN from '../images/inc.png'
import WD from '../images/team.png'
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
const config = require("../config.json");

export default function Dash({ ipAddress, loginData }) {
  const [account] = useState(loginData?.address);
  const [loading, setLoading] = useContext(LoadingContext);
  const [referall, setReferall] = useState("");
  const [dash, setDash] = useState({});
  const history = useHistory();
  const shows = async () => {
    Swal.fire({
      icon: "info",
      title: "Plus Web",
      text: "Your refrrel code is copyed",
    });
  };
  const handleDash = useCallback(async () => {
    setLoading(true);
    let data = JSON.stringify({
      ip: ipAddress,
      ulid: loginData.ulid,
    });
    let axiosConfig = {
      method: "post",
      url: `${config.baseUrl}desk`,
      headers: {
        ip: ipAddress,
        ulid: loginData.ulid,
        auth: loginData.auth,
        token: loginData.token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(axiosConfig)
    let response = await axios.request(axiosConfig);
    console.log(response.data);
    response = response.data;
    setLoading(false);
    if (response.status) {
      setDash(response.info);
    } else if (response.code === 30) {
      Swal.fire({
        icon: "info",
        title: "Plus Web",
        text: response?.message,
      }).then(() => {
        history.push("/register");
      });
    }
  }, [
    history,
    ipAddress,
    loginData.auth,
    loginData.token,
    loginData.ulid,
    setLoading,
  ]);

  useEffect(() => {
    handleDash();
  }, [handleDash]);
  return (
    <>
      <div className="layout-container">
        <Menu />
        <div className="layout-page">
          <Header />
          {loading ? (
            <>
              <ReactLoader />
            </>
          ) : (
            <div className="content-wrapper mb-4">
              <div className="container-xxl flex-grow-1 container-p-y pt-2">
                <div className="row">
                  <div className="col-md-12  mb-2">
                    <div className="card h-100">
                      <div
                        className="card-header align-items-center"
                        style={{ padding: "4% 5% 5% 5%" }}
                      >
                        <div className="col-md-12">
                          <div className="row d-flex justify-content-between">
                            <div className="col-12 text-center">
                              <p className="card-text m-0 text-black  text-md">
                                <b>Plus Web Live</b>
                              </p>
                              <div className='row d-flex justify-content-between'>
                                <div className='col-6 ' style={{ textAlignLast: "start" }}>
                                  <small className="text-sm">Dear : {dash?.self?.name}</small>
                                </div>
                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                  <small className="text-sm">Mobile :  {dash?.self?.mobile}</small>
                                </div>
                              </div>
                              <div className='row d-flex justify-content-between'>
                                <div className='col-6 ' style={{ textAlignLast: "start" }}>
                                  <small className="text-sm">Email : {dash?.self?.email}</small>
                                </div>
                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                  <small className="text-sm">Referral Code :  {dash?.sponsorId}</small>
                                </div>
                              </div>
                              <div className='row d-flex justify-content-between'>
                                <div className='col-6 ' style={{ textAlignLast: "start" }}>
                                  <Link
                                    to="/buynow"
                                    className="btn btn-secondary btn-xs rounded-pill text-xs me-sm-3 mt-2"
                                  >
                                    Buy Package
                                  </Link>
                                </div>
                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                  <CopyToClipboard text={dash?.copyLink} >
                                    <button onClick={shows} className="btn btn-secondary btn-xs text-sm me-3 mt-2 rounded-pill" style={{ textAlignLast: "end" }}>
                                      Copy Code
                                    </button>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 text-center mb-2'>
                    <div >
                      <ul className="navbar-nav d-flex justify-content-around flex-row nav-footer mt-md-1" >
                        <li className="nav-item ms-1 me-xl-0">
                          <Link to="/profile">
                            <div className='d-flex flex-column'>
                              <img src={HM} alt="" className="w-px-40 h-auto " />
                              <span className='text-black text-center' style={{ fontSize: '8px' }}>PROFILE</span>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-item ms-1 me-xl-0">
                          <Link to="/support">
                            <div className='d-flex flex-column'>
                              <img src={PK} alt="" className="w-px-40 h-auto " />
                              <span className='text-black text-center' style={{ fontSize: '8px' }}>SUPPORT</span>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-item ms-1 me-xl-0">
                          <Link to="/package">
                            <div className='d-flex flex-column'>
                              <img src={BY} alt="" className="w-px-40 h-auto " />
                              <span className='text-black text-center' style={{ fontSize: '8px' }}>TOPUP</span>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-item ms-1 me-xl-0">
                          <Link to="/business">
                            <div className='d-flex flex-column'>
                              <img src={IN} alt="" className="w-px-40 h-auto " />
                              <span className='text-black text-center' style={{ fontSize: '8px' }}>INCOME</span>
                            </div>
                          </Link>
                        </li>
                        <li className="nav-item ms-1 me-xl-0">
                          <Link to="/downline">
                            <div className='d-flex flex-column'>
                              <img src={WD} alt="" className="w-px-40 h-auto " />
                              <span className='text-black text-center' style={{ fontSize: '8px' }}>TEAM</span>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body dashinc">
                        <div className="d-flex justify-content-between">
                          <div className="card-info">
                            <p className="card-text m-0 text-sm">Wallet Balance</p>
                            <div className="d-flex align-items-end mb-2">
                              <small className="text-black ">
                                <i className="bx bx-rupee bx-xs"></i>{" "}
                                {dash?.walletMain}
                              </small>
                            </div>
                          </div>
                          <div className="card-icon">
                            <span className="badge bg-label-warning rounded p-2">
                              <i className="bx bx-rupee bx-sm"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body dashinc">
                        <div className="d-flex justify-content-between">
                          <div className="card-info">
                            <p className="card-text m-0 text-black text-sm">
                              TOPUP Balance
                            </p>
                            <div className="d-flex align-items-end mb-2">
                              <small className="text-black ">
                                <i className="bx bx-rupee bx-xs"></i>
                                {dash?.walletTopup}
                              </small>
                            </div>
                          </div>
                          <div className="card-icon">
                            <span className="badge bg-label-warning rounded p-2">
                              <i className="bx bx-rupee bx-sm"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12  mb-2">
                    <div className="card h-100">
                      <div
                        className="card-header align-items-center"
                        style={{ padding: "4% 5% 5% 5%" }}
                      >
                        <div className="col-md-12">
                          <div className="row d-flex justify-content-between">
                            <div className="col-12 text-center">
                              <p className="card-text m-0 text-black  text-md">
                                <b>Team/Direct</b>
                              </p>
                              <div className='row d-flex justify-content-between'>
                                <div className='col-6 ' style={{ textAlignLast: "start" }}>
                                  <small className="text-sm">Team : {parseInt(dash?.totalteam?.active) + parseInt(dash?.totalteam?.inactive)}</small>
                                </div>
                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                  <small className="text-sm">Active :  {parseInt(dash?.totalteam?.active)}</small>
                                </div>
                              </div>
                              <div className='row d-flex justify-content-between'>
                                <div className='col-6 ' style={{ textAlignLast: "start" }}>
                                  <small className="text-sm">Inactive : {parseInt(dash?.totalteam?.inactive)}</small>
                                </div>
                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                  <small className="text-sm">Business : <i className="bx bx-rupee bx-xs"></i>  {dash?.totalteam?.buss}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12  mb-2">
                    <div className="card h-100">
                      <div
                        className="card-header align-items-center"
                        style={{ padding: "4% 5% 5% 5%" }}
                      >
                        <div className="col-md-12">
                          <div className="row d-flex justify-content-between">
                            <div className="col-12 text-center">
                              <p className="card-text m-0 text-black  text-md">
                                <b>Direct</b>
                              </p>
                              <div className='row d-flex justify-content-between'>
                                <div className='col-6 ' style={{ textAlignLast: "start" }}>
                                  <small className="text-sm">Total : {parseInt(dash?.direct?.active) + parseInt(dash?.direct?.inactive)}</small>
                                </div>
                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                  <small className="text-sm">Active :  {parseInt(dash?.direct?.active)}</small>
                                </div>
                              </div>
                              <div className='row d-flex justify-content-between'>
                                <div className='col-6 ' style={{ textAlignLast: "start" }}>
                                  <small className="text-sm">Inactive : {parseInt(dash?.direct?.inactive)}</small>
                                </div>
                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                  <small className="text-sm">Business : <i className="bx bx-rupee bx-xs"></i>  {dash?.direct?.buss}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body dashinc">
                        <div className="d-flex justify-content-between">
                          <div className="card-info">
                            <p className="card-text m-0 text-sm">
                              Total Income
                            </p>
                            <div className="d-flex align-items-end mb-2">
                              <small className="text-black ">
                                <i className="bx bx-rupee bx-xs"></i>{" "}
                                {dash?.totalIncome}
                              </small>
                            </div>
                          </div>
                          <div className="card-icon">
                            <span className="badge bg-label-warning rounded p-2">
                              <i className="bx bx-rupee bx-sm"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body dashinc">
                        <div className="d-flex justify-content-between">
                          <div className="card-info">
                            <p className="card-text m-0 text-sm">
                              Today Income
                            </p>
                            <div className="d-flex align-items-end mb-2">
                              <small className="text-black ">
                                <i className="bx bx-rupee bx-xs"></i>
                                {dash?.todayIncome}
                              </small>
                            </div>
                          </div>
                          <div className="card-icon">
                            <span className="badge bg-label-warning rounded p-2">
                              <i className="bx bx-rupee bx-sm"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
