import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';

import Swal from 'sweetalert2'
const config = require('../config.json')
export default function Package({ ipAddress, loginData }) {

    const [req, setReq] = useState('')
    const [tran, setTran] = useState('')
    const [info, setInfo] = useState(0)
    const [amt, setAmt] = useState(0)
    const [img, setImg] = useState(null)
    const [up, setUp] = useState(true)
    const handleLeverage = () => {
        //console.log(img)
        if (img === null) {
            Swal.fire('Plus Web', "Please Select Screen Shot for transaction", 'warning')
            return
        }

        let data = JSON.stringify({
            "ip": ipAddress,
            "amt": amt,
            "info": info,
            "img": img,
            "tran": tran
        });
        Swal.fire('Plus Web', "Please wait for geting Information", 'info')
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/topupreqest`,
            headers: {
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data);
                let res = response.data;
                Swal.fire({
                    title: "Plus Web",
                    icon: 'info',
                    text: res?.message
                })
                getRequest()
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const getRequest = useCallback(() => {

        let data = JSON.stringify({
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}requestinfo`,
            headers: {
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                setReq(response.data.data)
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ipAddress, loginData])
    useEffect(() => {
        getRequest()
    }, [getRequest])

    const getBase64 = file => {
        return new Promise(resolve => {
            //let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //console.log("Called", reader);
                baseURL = reader.result;
                //console.log(baseURL);
                resolve(baseURL);
            };
            //console.log(fileInfo);
        });
    };

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };
    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2 mb-4">
                            <div>
                                {up ?
                                    <>
                                        <div className='row'>
                                            <div className="col-md-12  mb-2">
                                                <div className="card">
                                                    <div className="card-header align-items-center ">
                                                        <div className="card-title mb-0 text-center">
                                                            <h6 className="m-0 me-2 text-center text-black">Topup Request</h6>
                                                            <span style={{ fontSize: "10px" }}>Add Fund to topup wallet</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4  mb-1">
                                                <div className="card h-100">
                                                    <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>
                                                        <div className="col-md-12">
                                                            <div className='row d-flex justify-content-between'>
                                                                <div className='col-4 text-left'>
                                                                    <small className="text-sm">Topup Wallet</small>
                                                                </div>
                                                                <div className='col-8' style={{ textAlignLast: "end" }}>
                                                                    <small className="text-sm"><i className='bx bx-rupee bx-xs'></i> {req?.top}</small>
                                                                </div>
                                                            </div>
                                                            <div className='row d-flex justify-content-between'>
                                                                <div className='col-6 text-left'>
                                                                    <span className="text-black text-sm">Main Wallet</span>
                                                                </div>
                                                                <div className='col-6' style={{ textAlignLast: "end" }}>
                                                                    <small className="text-sm"><i className='bx bx-rupee bx-xs'></i> {req?.main}</small>
                                                                </div>
                                                            </div>
                                                            <div className="card-info align-items-center mb-2">
                                                                <div className='text-center mt-0'>
                                                                    <button className='btn  btn-warning rounded-pill btn-sm' onClick={() => setUp(false)}>Add amount to Topup</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>

                                            <div className="col-md-12  mb-2">
                                                <div className="card">
                                                    <div className="card-header align-items-center ">
                                                        <div className="card-title mb-0">
                                                            <h6 className="m-0 me-2 text-center text-black">Topup Request Status</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {req?.req?.map((list, i) => {
                                                // console.log(list)
                                                let cls = 'text-sm rounded-pill btn btn-warning btn-xs text-black'
                                                let st = 'Processing';
                                                if (list?.is_paid === 1) {
                                                    st = 'Paid';
                                                    cls = 'text-sm rounded-pill btn btn-info btn-xs text-black'
                                                }
                                                else if (list?.is_paid === 2) {
                                                    st = 'Cancled';
                                                    cls = 'text-sm rounded-pill btn btn-danger btn-xs text-white'
                                                }
                                                return (
                                                    <div className="col-md-12  mb-2">
                                                        <div className="card h-100">
                                                            <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>

                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <span className={cls}>{st}</span>
                                                                    </div>
                                                                    <div className='col-6' style={{ textAlignLast: "end" }}>
                                                                        <span className={'text-black text-sm'} style={{ fontSize: "14px" }}>{list?.dt}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <small className="text-sm text-black">Amount </small>
                                                                    </div>
                                                                    <div className='col-6 ' style={{ textAlignLast: "end" }}>
                                                                        <small className="text-sm text-black"><i className="bx bx-rupee bx-xs"></i>{list?.amt}</small>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <small className="text-sm text-black">Paid Amount  </small>
                                                                    </div>
                                                                    <div className='col-6 ' style={{ textAlignLast: "end" }}>
                                                                        <small className="text-sm text-black">{list?.upamt} </small>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-12 text-center'>
                                                                        <img src={list?.srcimg} alt='Transaction clip' width={"200px"} height={"auto"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    </>
                                    :
                                    <div className='row'>
                                        <div className="col-md-12  mb-2">
                                            <div className="card">
                                                <div className="card-header align-items-center ">
                                                    <div className="card-title mb-0">
                                                        <h6 className="m-0 me-2 text-center text-black mb-2">Upload Image of Transaction </h6>
                                                    </div>
                                                </div>
                                                <div className="card-body align-items-center">
                                                    <form id="formAuthentication" >
                                                        <div className="mb-3">
                                                            <label htmlFor="1email" className="form-label">Transaction Number</label>
                                                            <input type="text" className="form-control" placeholder="Enter User ID" onChange={(e) => setTran(e.target.value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="1email" className="form-label">Transaction Inforamtion</label>
                                                            <input type="text" className="form-control" placeholder="Other Inforamtion" onChange={(e) => setInfo(e.target.value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="e1mail" className="form-label">Amount</label>
                                                            <input type="text" className="form-control" placeholder="Enter your name" onChange={(e) => setAmt(e.target.value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="e1mail" className="form-label">Select Image</label>
                                                            <input type='file' className='form-controle rounded-pill' accept="image/*" id="mobile" name="mobile" placeholder="Contact Number" onChange={event => getBase64(event.target.files[0]).then(file => setImg(file))} />
                                                        </div>
                                                    </form>
                                                    <div className='text-center mt-0'>
                                                        <button className='btn  btn-secondary rounded-pill btn-sm me-2' onClick={() => handleLeverage()}>Upload Now</button>
                                                        <button className='btn  btn-warning rounded-pill btn-sm' onClick={() => setUp(true)}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>

                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div >
        </>
    );
}