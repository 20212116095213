import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import React, { useCallback, useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { LoadingContext } from "../context/LoadingContext";
import Swal from 'sweetalert2'
const config = require('../config.json')

export default function Leverage({ ipAddress, loginData }) {
    const [loading, setLoading] = useContext(LoadingContext);
    const [data, setData] = useState('')
    const [bal, setBal] = useState(0)

    const handleLeverage = (amt, pid) => {
        let data = JSON.stringify({
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "amt": amt,
            "pid": pid,
        });
        Swal.fire('Plus Web', "Please wait for geting Information", 'info')
        setLoading(true)
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/buy`,
            headers: {
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                setLoading(false)
                console.log(response.data);
                let res = response.data;
                Swal.fire({
                    title: "Plus Web",
                    icon: 'info',
                    text: res?.message
                })
                handleWallet()
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleWallet = useCallback(() => {

        let data = JSON.stringify({
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}package`,
            headers: {
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                setData(response.data.data.info)
                setBal(response.data.data.info.top)
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ipAddress, loginData.auth, loginData.token, loginData.ulid])
    useEffect(() => {
        handleWallet()
    }, [handleWallet])

    const handleValues = async (e, p) => {
        console.log(e);
        console.log(bal)
        if (bal >= parseFloat(e) && bal > 0) {
            Swal.fire({
                title: "Plus Web",
                icon: 'info',
                text: "Do you Want to Buy Package of Amount " + e
            }).then((res) => {
                if (res.isConfirmed) {
                    handleLeverage(e, p)
                }
            })
        }
        else {
            Swal.fire("Plus Web", "Please Check You Topup Wallet. Your Balance :" + bal, "info");
        }
    }
    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2 mb-4">
                            <div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                    <h6 className="m-0 text-center text-black ">My Packs/ You Can Buy</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2" key="n2">
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-black text-sm">Topup Wallet Balance </p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {data?.top}  </small>
                                                        </div>
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {data?.packs?.map((list, i) => {
                                        return (
                                            <>
                                                <div className="col-md-6  mb-2" id={i + 1} key={i + 1}>
                                                    <div className="card">
                                                        <div className="card-body dashinc">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="card-info">
                                                                    <p className="card-text m-0 text-black text-sm">{list.package_name} ({list.package_days} Days)</p>
                                                                    <div className="d-flex align-items-end mb-2">
                                                                        <small className="text-black text-sm"><i className='bx bx-rupee bx-xs'></i>  {list.package_amount}  </small>
                                                                    </div>
                                                                </div>
                                                                <div className="card-info">
                                                                    <p className="card-text m-0 text-black text-sm">Net <i className='bx bx-rupee bx-xs'></i>{list.package_net} </p>
                                                                    <div className="d-flex align-items-end mb-2">
                                                                        <small className="text-black text-sm">GST <i className='bx bx-rupee bx-xs'></i>{list.package_gst}  </small>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="card-info align-items-center mb-2">
                                                                <div className='text-center mt-0'>
                                                                    <button className='btn  btn-warning rounded-pill btn-sm' onClick={() => handleValues(list.package_net, list.id)}>Buy {list.package_name} of <i className='bx bx-rupee bx-xs'></i>{list.package_net}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                    <h6 className="m-0 me-2 text-center text-black">Buyed Packages</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        data?.invest?.map((list, index) => {
                                            return (
                                                <div className="col-md-4  mb-1" id={index + 1} key={index + 1}>
                                                    <div className="card h-100">
                                                        <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>
                                                            <div className="col-md-12">
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <span className="text-black text-sm">Amount : <i className='bx bx-rupee bx-xs'></i> {list?.invest_amount}</span>
                                                                    </div>
                                                                    <div className='col-6' style={{ textAlignLast: "end" }}>
                                                                        <small className="text-sm">Buy Date : {list?.buydate}</small>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <small className="text-sm">Package Name : {list?.pname}</small>
                                                                    </div>
                                                                    <div className='col-6' style={{ textAlignLast: "end" }}>
                                                                        <small className="text-sm">For {list?.pday} Days</small>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <small className="text-sm">Start Date </small>
                                                                    </div>
                                                                    <div className='col-6' style={{ textAlignLast: "end" }}>
                                                                        <small className="text-sm"> {list?.sdate}</small>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6 text-left'>
                                                                        <small className="text-sm">End Date </small>
                                                                    </div>
                                                                    <div className='col-6' style={{ textAlignLast: "end" }}>
                                                                        <small className="text-sm">{list?.edate}</small>
                                                                    </div>
                                                                </div>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-8 text-left'>
                                                                        <small className="text-sm">GST :<i className='bx bx-rupee bx-xs'></i>{list?.tax_amount}</small>
                                                                    </div>
                                                                    <div className='col-4' style={{ textAlignLast: "end" }}>
                                                                        <small className="text-sm">Total :<i className='bx bx-rupee bx-xs'></i>{list?.net_amount}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}