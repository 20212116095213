import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
const config = require('../config.json')

export default function Business({ipAddress, loginData}) {
    const [account, setAccount] = useState(loginData?.ulid);
  const [business,setBusiness] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const [businessTable,setBusinessTable] = useState({});
  const [mid ,  setOld] = useState(0);
  const handleBusiness = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/income`,
      headers: { 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        setBusiness(response.data.info)
        console.log(response.data)
      setBusinessTable(response.data)
    //   console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  const showmore = ((e) => {
    e.preventDefault()
    
    let data = JSON.stringify({
      "ip": ipAddress,
      "ulid": loginData.ulid,
      'mid'    : mid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/incomes`,
      headers: { 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        console.log(response.data)
      setBusinessTable(response.data)
    
    })
    .catch((error) => {
      console.log(error);
    });
  })

  useEffect(() => {
    
    handleBusiness()
  },[handleBusiness])

  
    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        {loading ? <><ReactLoader/></> :
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y pt-2">
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                            <h6 className="m-0 me-2 text-center text-black">Your Income</h6>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Total Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i> {business?.total}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Today Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i> {business?.today} </small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Yesterday Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i> {business?.yday}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    
                                    {
                                    business?.data?.map((list, index)=>{
                                        
                                        return ( 
                                        <div className="col-md-4  mb-1" key={index+1}>
                                            <div className="card h-100">
                                                <div className="card-header align-items-center" style={{padding :"3% 5% 3% 5%"}}>
                                                <div className="col-md-12">
                                                    <div className='row d-flex justify-content-between'>
                                                    <div className='col-6 text-left'>
                                                        <span className="text-black text-sm">Income - {list?.iname}</span>
                                                    </div>
                                                    <div className='col-6' style={{textAlignLast:"end"}}>
                                                    <span className="text-black text-sm">level - {list?.level}</span>
                                                    </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                    <div className='col-6 text-left'>
                                                        <small className="text-sm">Amount : <i className='bx bx-rupee bx-xs'></i>{list?.netAmount}</small>
                                                    </div>
                                                    <div className='col-6' style={{textAlignLast:"end"}}>
                                                        <small className="text-sm">Date : {list?.dt}</small>
                                                    </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                    <div className='col-6 text-left'>
                                                        <small className="text-sm">Refrence ID - {list?.refrence}</small>
                                                    </div>
                                                    <div className='col-6' style={{textAlignLast:"end"}}>
                                                        <small className="text-sm">OnAmount : <i className='bx bx-rupee bx-xs'></i>{list?.onTotal}</small>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                                        
                                        )
                                        })
                                    }
                                    
                                </div>
                            </div>
                            <Footer />
                        </div>
                    }
                    </div>
                </div>
        </>
    );
}
