import React, { useContext, useState, useEffect, useCallback } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import ReactLoader from '../components/ReactLoader';
import { LoadingContext } from '../context/LoadingContext';
import Swal from 'sweetalert2'

const config = require('../config.json')

export default function Request({ ipAddress, loginData }) {
  const [loading, setLoading] = useContext(LoadingContext)
  const [info, setInfo] = useState({})
  const [withd, setWith] = useState(true)
  const [self, setSelf] = useState(false)
  const [tran, setTran] = useState(false)
  const [reci, setReci] = useState(false)
  const [wamt, setWamt] = useState(0)
  const [tamt, setTamt] = useState(0)
  const [oamt, setOamt] = useState(0)
  const [lid, setLid] = useState(0)
  const handleWallet = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "ip": ipAddress,
      "ulid": loginData.ulid
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}wallet`,
      headers: {
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        setInfo(response.data.info)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {

    handleWallet()
  }, [handleWallet])

  const sendWith = (e) => {
    e.preventDefault()
    if (parseFloat(wamt) <= parseFloat(info?.main) && parseFloat(wamt) >= parseFloat(info?.minwr)) {
      let data = JSON.stringify({
        "ip": ipAddress,
        "ulid": loginData.ulid,
        'amt': wamt
      });

      let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}withdrawal`,
        headers: {
          'ip': ipAddress,
          'ulid': loginData.ulid,
          'auth': loginData.auth,
          'token': loginData.token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      // console.log(axiosConfig)
      axios.request(axiosConfig)
        .then((response) => {
          console.log(response.data)
          Swal.fire({
            icon: 'info',
            title: 'Plus Web',
            text: response.data?.message
          })
          setWamt(0)
          handleWallet();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Plus Web',
        text: "Please enter valid amount for withdrawal"
      })
    }
  }

  const sendTopup = (e) => {
    e.preventDefault()
    if (parseFloat(tamt) <= parseFloat(info?.main) && parseFloat(tamt) >= parseFloat(info?.minslef)) {
      let data = JSON.stringify({
        "ip": ipAddress,
        "ulid": loginData.ulid,
        'amt': tamt
      });

      let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}transfer`,
        headers: {
          'ip': ipAddress,
          'ulid': loginData.ulid,
          'auth': loginData.auth,
          'token': loginData.token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      // console.log(axiosConfig)
      axios.request(axiosConfig)
        .then((response) => {
          console.log(response.data)
          Swal.fire({
            icon: 'info',
            title: 'Plus Web',
            text: response.data?.message
          })
          setWamt(0)
          handleWallet();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Plus Web',
        text: "Please enter valid amount for withdrawal"
      })
    }
  }

  const checkLid = (e) => {
    console.log(e, e.length)
    let lid = e;
    if (lid.length > 4) {
      let data = JSON.stringify({
        "ip": ipAddress,
        "ulid": loginData.ulid,
        'lid': lid
      });

      let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}checkdown`,
        headers: {
          'ip': ipAddress,
          'ulid': loginData.ulid,
          'auth': loginData.auth,
          'token': loginData.token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      // console.log(axiosConfig)
      axios.request(axiosConfig)
        .then((response) => {
          console.log(response.data)
          Swal.fire({
            icon: 'info',
            title: 'Plus Web',
            text: response.data?.message
          })
          setLid(lid)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Plus Web',
        text: "Please enter valid Memeber ID"
      })
    }
  }

  const sendTopupot = (e) => {
    e.preventDefault()
    if (parseFloat(oamt) <= parseFloat(info?.top)) {
      let data = JSON.stringify({
        "ip": ipAddress,
        "ulid": loginData.ulid,
        'amt': oamt,
        'lid': lid
      });

      let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}transferother`,
        headers: {
          'ip': ipAddress,
          'ulid': loginData.ulid,
          'auth': loginData.auth,
          'token': loginData.token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      // console.log(axiosConfig)
      axios.request(axiosConfig)
        .then((response) => {
          console.log(response.data)
          Swal.fire({
            icon: 'info',
            title: 'Plus Web',
            text: response.data?.message
          })
          setWamt(0)
          handleWallet();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Plus Web',
        text: "Please enter valid Amount"
      })
    }
  }
  return (
    <>
      <div className="layout-container">
        <Menu />
        <div className="layout-page">
          <Header />
          {loading ? <><ReactLoader /></> :
            <div className="content-wrapper mb-4">
              <div className="container-xxl flex-grow-1 container-p-y pt-2">
                <div className='row'>
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body dashinc">
                        <div className="d-flex justify-content-between">
                          <div className="card-info">
                            <p className="card-text m-0 text-black text-sm">Wallet Balance </p>
                            <div className="d-flex align-items-end mb-2">
                              <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {info?.main}  </small>
                            </div>

                          </div>
                          <div className="card-icon">
                            <span className="badge bg-label-warning rounded p-2">
                              <i className='bx bx-rupee bx-sm'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body dashinc">
                        <div className="d-flex justify-content-between">
                          <div className="card-info">
                            <p className="card-text m-0 text-black text-sm">Topup Balance </p>
                            <div className="d-flex align-items-end mb-2">
                              <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {info?.top}  </small>
                            </div>

                          </div>
                          <div className="card-icon">
                            <span className="badge bg-label-warning rounded p-2">
                              <i className='bx bx-rupee bx-sm'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body dashinc">
                        <div className="d-flex justify-content-between">
                          <div className="card-info">
                            <p className="card-text m-0 text-black text-sm">Total Withdrawal </p>
                            <div className="d-flex align-items-end mb-2">
                              <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {info?.paid}  </small>
                            </div>
                          </div>
                          <div className="card-icon">
                            <span className="badge bg-label-warning rounded p-2">
                              <i className='bx bx-rupee bx-sm'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 text-center mb-2'>
                    <div className="btn-group justify-content-around" style={{ width: '100%' }}>
                      <button type="button" className="btn btn-info text-sm" jf-ext-button-ct="left" onClick={() => { setWith(true); setReci(false); setSelf(false); setTran(false) }}>Payout</button>
                      <button type="button" className="btn btn-warning text-sm" jf-ext-button-ct="middle" onClick={() => { setWith(false); setReci(false); setSelf(true); setTran(false) }}>Send Topup</button>
                      <button type="button" className="btn btn-info text-sm" jf-ext-button-ct="middle" onClick={() => { setWith(false); setReci(true); setSelf(false); setTran(false) }}>Recived Topup</button>
                      <button type="button" className="btn btn-warning text-sm" jf-ext-button-ct="right" onClick={() => { setWith(false); setReci(false); setSelf(false); setTran(true) }}>Send Other</button>
                    </div>
                  </div>
                </div>
                {withd &&
                  <>
                    {(info?.is) ?
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className="card mb-2">
                            <h5 className="card-header text-center text-black">Withdrawal Amount</h5>
                            <div className="card-body">
                              <form id="formAccountSettings2" onSubmit={sendWith} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                <div className="row">
                                  <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                    <label htmlFor="amt" className="form-label">Enter amount (Main Wallet Balance <i className='bx bx-rupee bx-xs'></i>{info?.main})</label>
                                    <input type="text" id="amt" name="companyName" className="form-control" placeholder="Enter Amount Form Withdrawal" onChange={(e) => { setWamt(e.target.value) }} />
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                    <span style={{ fontSize: '10px' }}>Minimume Limit<i className='bx bx-rupee bx-xs'></i>{info?.minwr}</span>
                                  </div>
                                </div>
                                <div className="mt-2 text-center">
                                  <button type="submit" className="btn btn-secondary me-2 text-sm">Withdrawal Now</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className='row'>

                        <div className="col-md-12  mb-2">
                          <div className="card">
                            <div className="card-header align-items-center ">
                              <div className="card-title mb-0">
                                <h6 className="m-0 me-2 text-center text-black">Minimum Withdrawal Limit <i className='bx bx-rupee bx-xs'></i>{info?.minwr} </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <div className='row'>

                      <div className="col-md-12  mb-2">
                        <div className="card">
                          <div className="card-header align-items-center ">
                            <div className="card-title mb-0">
                              <h6 className="m-0 me-2 text-center text-black">Withdrawal Request Status</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        info?.payout?.map((list, index) => {
                          return (
                            <div className="col-md-4  mb-1" key={index + 1}>
                              <div className="card h-100">
                                <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>
                                  <div className="col-md-12">
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-6 text-left'>
                                        <span className="text-black text-sm">Amount : <i className='bx bx-rupee bx-xs'></i> {list?.net}</span>
                                      </div>
                                      <div className='col-6' style={{ textAlignLast: "end" }}>
                                        <span className={`${(list.status === 'PAID') ? 'text-black text-sm rounded-pill btn btn-success btn-xs' : 'text-danger text-sm rounded-pill btn btn-warning btn-xs'}`} style={{ fontSize: "14px" }}>{list?.status}</span>
                                      </div>
                                    </div>
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-6 text-left'>
                                        <small className="text-sm">Request Amount : <i className='bx bx-rupee bx-xs'></i>{list?.amt}</small>
                                      </div>
                                      <div className='col-6' style={{ textAlignLast: "end" }}>
                                        <small className="text-sm">Date : {list?.dt}</small>
                                      </div>
                                    </div>
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-12 text-left'>
                                        <small className="text-sm">Refrence : {list?.th}</small>
                                      </div>
                                      <div className='col-4 text-left'>
                                        <small className="text-sm">Tax : <i className='bx bx-rupee bx-xs'></i>{list?.tax}</small>
                                      </div>
                                    </div>
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-8 text-left'>
                                        <small className="text-sm">Bank : {list?.bank}</small>
                                      </div>
                                      <div className='col-4' style={{ textAlignLast: "end" }}>
                                        <small className="text-sm">IFSC : {list?.ifsc}</small>
                                      </div>
                                    </div>
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-8 text-left'>
                                        <small className="text-sm">Account : {list?.ac}</small>
                                      </div>
                                      <div className='col-4' style={{ textAlignLast: "end" }}>
                                        <small className="text-sm">UPI ID : {list?.upi}</small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                  </>
                }
                {tran &&
                  <>
                    {(info?.is_self) ?
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className="card mb-2">
                            <h5 className="card-header text-center text-black">Send Downline Member Topup Wallet</h5>
                            <div className="card-body">
                              <form id="formAccountSettings2" onSubmit={sendTopupot} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                <div className="row">
                                  <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                    <label htmlFor="amt" className="form-label">Enter Member ID</label>
                                    <input type="text" id="amt" name="companyName" className="form-control" placeholder="Enter Memeber ID" onChange={(e) => { checkLid(e.target.value) }} />
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                  <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                    <label htmlFor="amt" className="form-label">Enter amount (Topup Wallet Balance <i className='bx bx-rupee bx-xs'></i>{info?.top})</label>
                                    <input type="text" id="amt" name="companyName" className="form-control" placeholder="Enter Amount send to memeber" onChange={(e) => { setOamt(e.target.value) }} />
                                    <div className="fv-plugins-message-container invalid-feedback" />

                                  </div>
                                </div>
                                <div className="mt-2 text-center">
                                  <button type="submit" className="btn btn-secondary me-2 text-sm">Send Now</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className='row'>

                        <div className="col-md-12  mb-2">
                          <div className="card">
                            <div className="card-header align-items-center ">
                              <div className="card-title mb-0">
                                <h6 className="m-0 me-2 text-center text-black">Minimum Limit to Send To Topup <i className='bx bx-rupee bx-xs'></i>{info?.minslef} </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <div className='row'>
                      <div className="col-md-12  mb-2">
                        <div className="card">
                          <div className="card-header align-items-center ">
                            <div className="card-title mb-0">
                              <h6 className="m-0 me-2 text-center text-black">Send to Other Memeber</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        info?.transfer?.map((list, index) => {
                          return (
                            <div className="col-md-4  mb-1" key={index + 1}>
                              <div className="card h-100">
                                <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>
                                  <div className="col-md-12">
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-4 text-left'>
                                        <small className="text-sm">Send To</small>
                                      </div>
                                      <div className='col-8' style={{ textAlignLast: "end" }}>
                                        <small className="text-sm">{list?.user_name} ( {list?.user_lid} )</small>
                                      </div>
                                    </div>
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-6 text-left'>
                                        <span className="text-black text-sm">Amount : <i className='bx bx-rupee bx-xs'></i> {list?.amt}</span>
                                      </div>
                                      <div className='col-6' style={{ textAlignLast: "end" }}>
                                        <small className="text-sm">Date : {list?.dt}</small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </>
                }
                {reci &&
                  <div className='row'>
                    <div className="col-md-12  mb-2">
                      <div className="card">
                        <div className="card-header align-items-center ">
                          <div className="card-title mb-0">
                            <h6 className="m-0 me-2 text-center text-black">Recived in Topup Wallet</h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      info?.recive?.map((list, index) => {
                        return (
                          <div className="col-md-4  mb-1" key={index + 1}>
                            <div className="card h-100">
                              <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>
                                <div className="col-md-12">
                                  <div className='row d-flex justify-content-between'>
                                    <div className='col-4 text-left'>
                                      <small className="text-sm">Recived From</small>
                                    </div>
                                    <div className='col-8' style={{ textAlignLast: "end" }}>
                                      <small className="text-sm">{list?.user_name} ( {list?.user_lid} )</small>
                                    </div>
                                  </div>
                                  <div className='row d-flex justify-content-between'>
                                    <div className='col-6 text-left'>
                                      <span className="text-black text-sm">Amount : <i className='bx bx-rupee bx-xs'></i> {list?.amt}</span>
                                    </div>
                                    <div className='col-6' style={{ textAlignLast: "end" }}>
                                      <small className="text-sm">Date : {list?.dt}</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }

                  </div>
                }
                {self &&

                  <>
                    {(info?.is_self) ?
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className="card mb-2">
                            <h5 className="card-header text-center text-black">Send to Topup Wallet</h5>
                            <div className="card-body">
                              <form id="formAccountSettings2" onSubmit={sendTopup} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                <div className="row">
                                  <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                    <label htmlFor="amt" className="form-label">Enter amount (Main Wallet Balance <i className='bx bx-rupee bx-xs'></i>{info?.main})</label>
                                    <input type="text" id="amt" name="companyName" className="form-control" placeholder="Enter Amount send to Topup" onChange={(e) => { setTamt(e.target.value) }} />
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                    <span style={{ fontSize: '10px' }}>Minimume Limit<i className='bx bx-rupee bx-xs'></i>{info?.minslef}</span>
                                  </div>
                                </div>
                                <div className="mt-2 text-center">
                                  <button type="submit" className="btn btn-secondary me-2 text-sm">Topup Now</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className='row'>

                        <div className="col-md-12  mb-2">
                          <div className="card">
                            <div className="card-header align-items-center ">
                              <div className="card-title mb-0">
                                <h6 className="m-0 me-2 text-center text-black">Minimum Limit to Send To Topup <i className='bx bx-rupee bx-xs'></i>{info?.minslef} </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    <div className='row' >
                      <div className="col-md-12  mb-2">
                        <div className="card">
                          <div className="card-header align-items-center ">
                            <div className="card-title mb-0">
                              <h6 className="m-0 me-2 text-center text-black">Main Wallet to Topup Wallet</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        info?.self?.map((list, index) => {
                          return (
                            <div className="col-md-4  mb-1" key={index + 1}>
                              <div className="card h-100">
                                <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>
                                  <div className="col-md-12">
                                    <div className='row d-flex justify-content-between'>
                                      <div className='col-6 text-left'>
                                        <span className="text-black text-sm">Amount : <i className='bx bx-rupee bx-xs'></i> {list?.amt}</span>
                                      </div>
                                      <div className='col-6' style={{ textAlignLast: "end" }}>
                                        <small className="text-sm">Date : {list?.dt}</small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                  </>
                }
              </div>
              <Footer />
            </div>
          }
        </div>
      </div >
    </>
  );
}
