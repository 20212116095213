import React, { useContext, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MM from '../images/logo.png'
import { IpContext } from '../context/IpContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { MobileSidebarContext } from '../context/MobileSidebarContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
const config = require('../config.json')

export default function Menu() {

  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const [isSubWalletOpen, setIsSubWalletOpen] = useState(false)
  const [ipAddress] = useContext(IpContext);
  const [mobileOpen, setMobileOpen] = useContext(MobileSidebarContext)
  const { height, width } = useWindowDimensions();

  const history = useHistory();


  const handleLogout = async () => {
    // disconnectWallet()
    try {
      const loginData = JSON.parse(localStorage.getItem('loginData'));
      let data = JSON.stringify({
        "address": loginData?.address,
        "ip": ipAddress,
        "ulid": loginData?.ulid
      });

      let axiosConfig = {
        method: 'post',
        url: `${config.baseUrl}logout`,
        headers: {
          'address': loginData?.address,
          'ip': ipAddress,
          'ulid': '6049090',
          'auth': loginData?.auth,
          'token': loginData?.token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      let response = await axios.request(axiosConfig)
      response = response.data
      // console.log('logging out')
      // console.log(response)
      localStorage.removeItem('loginData');
      history.push('/');

    } catch (error) {
      console.error(error)
    }
  };

  const handleMouseEnter = () => {
    if (width > 1199.98) {
      setTimeout(() => setIsOpen(true), 100)
    }
    // setTimeout(()=>setIsSubMenuOpen(true),100)

  };

  const handleMouseLeave = () => {
    if (width > 1199.98) {
      setTimeout(() => setIsOpen(false), 100)
      setIsSubMenuOpen(false)
      setIsSubWalletOpen(false)
    }
    // setIsSubMenuOpen(false)
  };
  let sidebarStyle = {}
  if (width > 1199.98) {
    sidebarStyle = {
      // display: isOpen ? 'block' : 'none',
      width: isOpen ? '16.25rem' : '5rem'

      // Add other styles as needed
    }
  } else {
    if (mobileOpen) {
      sidebarStyle = {
        // display: isOpen ? 'block' : 'none',
        transform: 'translate3d(0, 0, 0)'
        // Add other styles as needed
      }
    } else {
      sidebarStyle = {
        // display: isOpen ? 'block' : 'none',
        transform: 'translate3d(-100%, 0, 0)'
        // Add other styles as needed
      }
    }

  }

  const subMenuStyle = {
    display: isSubMenuOpen ? 'block' : 'none',
  }
  const subWalletStyle = {
    display: isSubWalletOpen ? 'block' : 'none',
  }

  useEffect(() => {
    //console.log("mobile open",mobileOpen)
    if (mobileOpen) {
      setIsOpen(true)
      // setIsSubMenuOpen(true)
    } else {
      setIsOpen(false)
      // setIsSubMenuOpen(false)
    }
  }, [mobileOpen])
  return (

    <aside id="layout-menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="layout-menu menu-vertical menu bg-menu-theme" style={sidebarStyle}>
      <div className="app-brand demo ">
        <Link to="/mentor" className="app-brand-link">
          <span className="app-brand-logo demo text-center">
            <img src={MM} style={{ height: '60px', width: 'auto' }} alt='ficon' />
          </span>

        </Link>
        {isOpen ? <span className="layout-menu-toggle menu-link text-large ms-auto">
          <i className="bx bx-chevron-left bx-sm align-middle" />
        </span> : ""}
      </div>
      <div className="menu-inner-shadow" />
      <ul className="menu-inner py-1">
        {/* Dashboards */}
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/dash" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bx-home-heart" />
            {isOpen ? <div data-i18n="Dashboards">Home</div> : ""}
          </Link>
        </li>
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/profile" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bxs-user-account" />
            {isOpen ? <div data-i18n="Layouts">Profile</div> : ""}
          </Link>
        </li>
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/buynow" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bxs-package" />
            {isOpen ? <div data-i18n="Kanban">My Package</div> : ''}
          </Link>
        </li>
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/package" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bxs-cart-alt" />
            {isOpen ? <div data-i18n="Kanban">Topup Now</div> : ''}
          </Link>
        </li>
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/downline" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bx-street-view" />
            {isOpen ? <div data-i18n="Kanban">Team</div> : ''}
          </Link>
        </li>
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/business" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bx-rupee" />
            {isOpen ? <div data-i18n="Layouts">Income</div> : ""}
          </Link>
        </li>
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/wallet" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bx-party" />
            {isOpen ? <div data-i18n="Layouts">Payout</div> : ""}
          </Link>
        </li>
        <li className="menu-item " onClick={() => setMobileOpen(false)}>
          <Link to="/support" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bx-support" />
            {isOpen ? <div data-i18n="Roles & Permissions">Support</div> : ''}
          </Link>
        </li>
        <li className="menu-item " >
          <Link onClick={() => handleLogout()} to="/" className="menu-link text-mid">
            <i className="menu-icon tf-icons bx bx-exit" />
            {isOpen ? <div data-i18n="Login">Disconnect</div> : ''}
          </Link>
        </li>
      </ul>
    </aside>
  );
}
