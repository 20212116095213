import React, { useContext, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const config = require('../config.json')

export default function Ticket({ ipAddress, loginData }) {
    const [account] = useState(loginData?.address);

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const handleTicket = (e) => {
        e.preventDefault()
        if (subject.length > 10 && message.length > 0) {
            let data = JSON.stringify({
                "address": account,
                "ip": ipAddress,
                "ulid": loginData.ulid,
                "subject": subject,
                "message": message
            });

            let axiosConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${config.baseUrl}ticket`,
                headers: {
                    'address': account,
                    'ip': ipAddress,
                    'ulid': loginData.ulid,
                    'auth': loginData.auth,
                    'token': loginData.token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios.request(axiosConfig)
                .then((response) => {
                    // console.log(response.data)
                    let res = response.data
                    if (res.status) {
                        Swal.fire({
                            icon: 'info',
                            title: 'Plus Web',
                            text: res.message
                        });
                        setMessage('');
                        setSubject('')
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Plus Web',
                            text: res.message
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: 'Plus Web',
                text: "Please enter subject and message"
            });
        }
    }

    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2">
                            <div className='row'>
                                <div className="col-md-12  mb-2">
                                    <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                                <h6 className="m-0 text-center text-black">Genrate Tickets</h6>
                                            </div>
                                            <div className='text-center mt-3'>
                                                <Link to="/support" className="btn btn-secondary text-nowrap btn-sm">
                                                    <i className="bx bxs-hand-right me-1" />View Ticket
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-12'>

                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <form
                                                onSubmit={handleTicket}
                                                className="fv-plugins-bootstrap5 fv-plugins-framework"
                                            >
                                                <div className="row">

                                                    <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                        <label htmlFor="billingEmail" className="form-label">
                                                            Subject
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="subject"
                                                            name="subject"
                                                            placeholder="Enter Subject"
                                                            value={subject}
                                                            onChange={(e) => setSubject(e.target.value)}
                                                        />
                                                        <div className="fv-plugins-message-container invalid-feedback" />
                                                    </div>
                                                    <div className="mb-3 col-sm-12">
                                                        <label htmlFor="taxId" className="form-label">
                                                            Message
                                                        </label>
                                                        <textarea
                                                            id="taxId"
                                                            name="taxId"
                                                            className="form-control"
                                                            placeholder="Enter Message"
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            value={message}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-2 col-sm-12 text-center">
                                                    <button type="submit" className="btn btn-warning btn-sm me-2">
                                                        Process Ticket
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
