import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'

const config = require('../config.json')
export default function Wallet({ ipAddress, loginData }) {
    const [account] = useState(loginData?.ulid);
    const [wallet, setWallet] = useState({})
    const [count, setCount] = useState(0)

    const handleWallet = useCallback(() => {

        let data = JSON.stringify({
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/wallet`,
            headers: {
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.log(axiosConfig)
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.code === 40) {
                    Swal.fire('Plus Web', response.data.message, 'info');
                }
                else {
                    if (response.data.status) {
                        setWallet(response.data?.info)
                    }
                    else {
                        Swal.fire('Plus Web', response.data.message, 'info');
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [account, ipAddress, loginData.auth, loginData.token, loginData.ulid])

    const handelNext = () => {
        console.log(wallet);
        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            'wid': wallet?.wid,
            'cid': wallet?.contact?.id,
            'mid': wallet?.msg?.id
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/api2/next`,
            headers: {
                'address': account,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.log(axiosConfig)
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data)
                if (response.data?.code === 40) {
                    Swal.fire('Plus Web', response.data.message, 'info');
                }
                else {
                    if (response.data.status) {
                        setWallet(response.data?.task?.info)
                        setCount(response.data?.count)
                    }
                    else {
                        Swal.fire('Plus Web', response.data.message, 'info');
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {

        handleWallet()
    }, [handleWallet])

    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2 mb-4">
                            <div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                    <h6 className="m-0 me-2 text-center text-black">Wallet Transfer Request</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12  mb-1">
                                        <div className="card h-100">
                                            <div className="card-header align-items-center" style={{ padding: "3% 5% 3% 5%" }}>
                                                <div className="col-md-12">
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-4 text-left'>
                                                            <small className="text-sm">Main Wallet</small>
                                                        </div>
                                                        <div className='col-8' style={{ textAlignLast: "end" }}>
                                                            <small className="text-sm"><i className='bx bx-rupee bx-xs'></i> {wallet?.main}</small>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-6 text-left'>
                                                            <span className="text-black text-sm">Topup Wallet</span>
                                                        </div>
                                                        <div className='col-6' style={{ textAlignLast: "end" }}>
                                                            <small className="text-sm"><i className='bx bx-rupee bx-xs'></i> {wallet?.top}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
