import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useState } from 'react';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dash from "./pages/Dash";
import Business from "./pages/Business";
import Downline from "./pages/Downline";
import Income from "./pages/Income";
import Profile from "./pages/Profile";
import Request from "./pages/Request";
import Support from "./pages/Support";
import NotFound from './pages/NotFound';
import Ticket from './pages/Ticket';
import Wallet from './pages/Wallet';
import Leverage from './pages/leverage'
import Task from './pages/Task';
import PrivateRoute from './pages/PrivateRoute';
import Package from './pages/package';
function App() {
  const [ipAddress] = useState("108.108.108.108");
  const [token, setToken] = useState('');
  const handleLogin = (token) => {
    setToken(token);
  };
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/"><Login ipAddress={ipAddress} onLogin={handleLogin} /></Route>
        <Route path="/register"><Register ipAddress={ipAddress} /></Route>
        <PrivateRoute exact path="/dash" component={Dash} />
        <PrivateRoute exact path="/business" component={Business} />
        <PrivateRoute exact path="/downline" component={Downline} />
        <PrivateRoute exact path="/income" component={Income} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/wallet" component={Request} />
        <PrivateRoute exact path="/support" component={Support} />
        <PrivateRoute exact path="/ticket" component={Ticket} />
        <PrivateRoute exact path="/package" component={Package} />
        <PrivateRoute exact path="/buynow" component={Leverage} />
        <PrivateRoute exact path="/topup" component={Wallet} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
