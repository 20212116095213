import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import HM from '../images/home.png'
import PK from '../images/details.png'
import BY from '../images/buys.png'
import IN from '../images/inc.png'
import WD from '../images/wallet.png'

export default function Footer() {
  return (
    <div>
      <footer className="navbar navbar-expand-xl navbar-detached align-items-center app-footer d-lg-none d-md-block d-sm-block">
        {/* <div className="menu-footer justify-content-around">
              <div className="menu-item">
                <div  className="menu-link flex-column active">
                  <i className="bx bx-home-heart bx-xs"></i>
                  <span style={{fontSize: 7}}>Home</span>
                </div>
              </div>
              <div className="menu-item">
                <div  className="menu-link flex-column active">
                  <i className="bx bx-package bx-xs"></i>
                  <span style={{fontSize: 7}}>Package</span>
                </div>
              </div>
              <div className="menu-item">
                <div  className="menu-link flex-column active">
                  <i className="bx bx-rupee bx-xs"></i>
                  <span style={{fontSize: 7}}>Income</span>
                </div>
              </div>
              <div className="menu-item">
                <div  className="menu-link flex-column active">
                  <i className="bx bx-party bx-xs"></i>
                  <span style={{fontSize: 7}}>Wallet</span>
                </div>
              </div>
            </div> */}

        <ul className="navbar-nav d-flex justify-content-around flex-row nav-footer mt-md-1" >
          <li className="nav-item ms-1 me-xl-0">
            <Link to="/dash">
              <div className='d-flex flex-column'>
                <img src={HM} alt="" className="w-px-40 h-auto " />
                <span className='text-black text-center' style={{ fontSize: '8px' }}>HOME</span>
              </div>
            </Link>
          </li>
          <li className="nav-item ms-1 me-xl-0">
            <Link to="/buynow">
              <div className='d-flex flex-column'>
                <img src={PK} alt="" className="w-px-40 h-auto " />
                <span className='text-black text-center' style={{ fontSize: '8px' }}>PACKAGE</span>
              </div>
            </Link>
          </li>
          <li className="nav-item ms-1 me-xl-0">
            <Link to="/package">
              <div className='d-flex flex-column'>
                <img src={BY} alt="" className="w-px-40 h-auto " />
                <span className='text-black text-center' style={{ fontSize: '8px' }}>TOPUP</span>
              </div>
            </Link>
          </li>
          <li className="nav-item ms-1 me-xl-0">
            <Link to="/business">
              <div className='d-flex flex-column'>
                <img src={IN} alt="" className="w-px-40 h-auto " />
                <span className='text-black text-center' style={{ fontSize: '8px' }}>INCOME</span>
              </div>
            </Link>
          </li>
          <li className="nav-item ms-1 me-xl-0">
            <Link to="/wallet">
              <div className='d-flex flex-column'>
                <img src={WD} alt="" className="w-px-40 h-auto " />
                <span className='text-black text-center' style={{ fontSize: '8px' }}>PAYOUT</span>
              </div>
            </Link>
          </li>
        </ul>

      </footer>
      <div className="content-backdrop fade" />
    </div>
  );
}
