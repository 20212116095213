import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const config = require('../config.json')

export default function Register({ ipAddress }) {
    const [splid, setSplid] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [lid, setlid] = useState('')
    const [pass, setPass] = useState('')
    const [isreq, setIsreq] = useState(0)
    const history = useHistory();
    const handleRegister = async (e) => {
        e.preventDefault()
            let data = JSON.stringify({
                "splid": splid,
                "ip": ipAddress,
                "name" : name,
                "mobile" : mobile,
                "email" : email
            });

            let axiosConfig = {
                method: 'post',
                url: `${config.baseUrl}register`,
                headers: {
                    'ip': ipAddress,
                    'Content-Type': 'application/json',
                },
                data: data
            };
            axios.request(axiosConfig)
                .then((response) => {
                    
                    let res = (response.data);
                    console.log(res.message);
                    if (res.status) {
                        Swal.fire({
                            icon: 'info',
                            title: 'Plus Web',
                            text: res.message
                        })
                        setlid(res?.data?.ulid)
                        setPass(res?.data?.password)
                        setIsreq(1)
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Plus Web',
                            text: res.message
                        })
                        
                        if (res.code === 30) {
                            history.push('/');
                        }
                    }

                })
                .catch((error) => {
                    console.log(error);
                });
    }
    const handleSlid = (e) => {
        e.preventDefault()
        checkSplid(splid);
    }

    function checkSplid(slid) {
        if (slid.length > 4 && ipAddress.length > 6) {
            let data = JSON.stringify({
                "splid": slid,

            });

            let axiosConfig = {
                method: 'post',
                url: `${config.baseUrl}checksponsor`,
                headers: {
                    'ip': ipAddress,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //   console.log(axiosConfig)
            axios.request(axiosConfig)
                .then((response) => {
                    console.log(response);
                    let res = (response.data);
                    if (res.status) {
                        setSplid(slid)
                        Swal.fire({
                            icon: 'info',
                            title: 'Plus Web',
                            text: res.message
                        })
                    }
                    else {
                        setSplid('');
                        Swal.fire({
                            icon: 'warning',
                            title: 'Plus Web',
                            text: res.message
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const type = queryParameters.get("slid")
        if (type) {
            if(splid.length < 3)
                checkSplid(type);
        }
    })
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                    {/* Forgot Password */}
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center" style={{ marginBottom: '10px' }}>
                                {/* go back to login pages */}
                                <Link to="/" className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                        <img src="logo.png" style={{ height: '150px', width: 'auto' }} alt="ficon" />
                                    </span>
                                </Link>
                            </div>
                            { (isreq === 1) ? <>
                                <h4 className="mb-2 text-black">Dear {lid} 🔒</h4>
                            <p className="mb-4">Your UserID : {lid}</p>
                            <p className="mb-4">Your Password : {pass}</p>
                                </> :<>
                            <form id="formAuthentication" className="mb-2" onSubmit={handleRegister}>
                                <div className="mb-2">
                                    <label htmlFor="1email" className="form-label">Sponsor ID</label>
                                    <input type="text" className="form-control" id="splid" name="splid" placeholder="Enter Sponsor ID" value={splid} onChange={(e) => setSplid(e.target.value)} onBlur={handleSlid} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="e1mail" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="2email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Enter your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="2email" className="form-label">Contact Number</label>
                                    <input type="number" className="form-control"  placeholder="Contact Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                </div>
                                <button className="btn btn-secondary d-grid w-100 d-flex"><i className="fas fa-user-plus me-2 text-info"></i>Register Now</button>
                            </form>
                            </>}
                            <div className="text-center">
                                {/* go back to login php */}
                                <Link to="/" className="d-flex align-items-center justify-content-center text-black ">
                                    <i className="fas fa-lock me-2 text-danger" ></i>Back to login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
