import React, { useCallback, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import Swal from 'sweetalert2';
const config = require('../config.json')

export default function Profile({ipAddress, loginData}) {
    const [account] = useState(loginData?.ulid);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [bname, setBname] = useState('');
    const [ifsc, setifsc] = useState('');
    const [acno, setAcno] = useState('');
    const [upi, setUpi] = useState('');
    const [pan, setPan] = useState('');
    const [oldp, setOld] = useState('');
    const [newp, setNew] = useState('');
    const [rnew, setRnew] = useState('');
    const [kyc, setKyc] = useState(true);
  const handleProfile = useCallback(() => {
    
    let data = JSON.stringify({
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}profile`,
      headers: { 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((res) => {
      let response = res.data;
        console.log(response)
        setName(response.data?.name)
        setEmail(response.data?.email)
        setMobile(response.data?.mobile)  
        setBname(response.data?.bank)  
        setifsc(response.data?.ifsc)  
        setAcno(response.data?.account)  
        setUpi(response.data?.upi)
        setPan(response.data?.pan)  
        setKyc(response.data?.kyc)   
    })
    .catch((error) => {
      // console.log(error);
    });
  },[account, ipAddress,  loginData.auth, loginData.token, loginData.ulid])

  
  useEffect(() => {
   handleProfile()
  },[handleProfile])

  const handleProfileEdit = (e) => {
    e.preventDefault()
    if(!kyc)
    {
    let data = JSON.stringify({
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "name" : name,
      "email"  : email,
      "mobile" : mobile,
      "bank" : bname,
      "ifsc" : ifsc,
      "account" : acno,
      "upi" : upi,
      "pan" : pan
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}profileedit`,
      headers: { 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(axiosConfig)  
    .then((res) => {
      let response = res.data;
      console.log(response);
        setName(response.data?.uname)
        setEmail(response.data?.uemail)
        setMobile(response.data?.umobile)
        setBname(response.data?.bank)  
        setifsc(response.data?.ifsc)  
        setAcno(response.data?.account)  
        setUpi(response.data?.upiid)
        setUpi(response.data?.pan)    
        Swal.fire('Plus Web', response?.message, 'info');
    })
    .catch((error) => {
      console.log(error);
    });
  }
  else
  {
    Swal.fire({
      icon: 'info',
      title: 'Plus Web',
      text: 'Your KYC is Verified. Please contact admin for change or genrate ticket for change.'
    });
  }
  }
  const handlePass = (e) => {
    e.preventDefault()
    if(newp !== rnew && newp.length < 6)
    {
      Swal.fire({
        icon: 'warning',
        title: 'Plus Web',
        text: 'Please Enter valid Password'
      });
    }
    let data = JSON.stringify({
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "opass" : oldp,
      "npass"  : newp,
      "rpass" : rnew
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}cpass`,
      headers: { 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
      console.log(response);
      if(response.status)
      {
        Swal.fire({
          icon: 'info',
          title: 'Plus Web',
          text: response.data.message
        });
        setOld('')
        setNew('')
        setRnew('')
      }
      else
      {
        Swal.fire({
          icon: 'warning',
          title: 'Plus Web',
          text: response.message
        });
      }
      
    })
    .catch((error) => {
      console.log(error);
    });
  }
    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="card mb-4">
                                            <h5 className="card-header text-center text-black">Your Profile</h5>
                                            <div className="card-body">
                                                <form id="formAccountSettings" onSubmit={handleProfileEdit} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                                    <div className="row">
                                                        <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="name" className="form-label">Full Name</label>
                                                            <input type="text" id="name" name="name" className="form-control" placeholder="Your Name" value={name} onChange={(e) => {setName(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="billingEmail" className="form-label">Email</label>
                                                            <input className="form-control" type="text" id="email" name="email" placeholder="Email ID"  value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-2 col-sm-6">
                                                            <label htmlFor="mobile" className="form-label">Contact Number</label>
                                                            <input className="form-control" type="text" id="mobile" name="mobile" placeholder="Contact Number"  value={mobile} onChange={(e) => {setMobile(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-2 col-sm-6">
                                                            <label htmlFor="pan" className="form-label">Pan Number</label>
                                                            <input className="form-control" type="text" id="pan" name="pan" placeholder="Pan Number"  value={pan} onChange={(e) => {setPan(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-2 col-sm-6">
                                                            <label htmlFor="bname" className="form-label">Bank Name</label>
                                                            <input className="form-control" type="text" id="bname" name="bname" placeholder="Bank Name"  value={bname} onChange={(e) => {setBname(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-2 col-sm-6">
                                                            <label htmlFor="ifsc" className="form-label">Bank IFSC Code</label>
                                                            <input className="form-control" type="text" id="ifsc" name="ifsc" placeholder="Bank IFSC Code"  value={ifsc} onChange={(e) => {setifsc(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-2 col-sm-6">
                                                            <label htmlFor="acno" className="form-label">Bank Account Number</label>
                                                            <input className="form-control" type="text" id="acno" name="acno" placeholder="Bank Account Number"  value={acno} onChange={(e) => {setAcno(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-2 col-sm-6">
                                                            <label htmlFor="upi" className="form-label">UPI  ID</label>
                                                            <input className="form-control" type="text" id="upi" name="upi" placeholder="UPI  ID"  value={upi} onChange={(e) => {setUpi(e.target.value)}}/>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 text-center">
                                                        <button type="submit" className="btn me-2 text-sm btn-secondary">Save changes</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="card mb-4">
                                            <h5 className="card-header text-center text-black">Update Password</h5>
                                            <div className="card-body">
                                                <form id="formAccountSettings2" onSubmit={handlePass} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                                    <div className="row">
                                                        <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="opass" className="form-label">Old Password</label>
                                                            <input type="text" id="opass" name="companyName" className="form-control" placeholder="Old Passowd"  onChange={(e) => {setOld(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="billingnpl" className="form-label">New Password</label>
                                                            <input className="form-control" type="text" id="np" name="np" placeholder="Enter New Password"   onChange={(e) => {setNew(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-2 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="billingenp" className="form-label">Re-enter New Password</label>
                                                            <input className="form-control" type="password"  placeholder="Re-Enter New Password"  onChange={(e) => {setRnew(e.target.value)}} />
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="mt-2 text-center">
                                                        <button type="submit" className="btn btn-secondary me-2 text-sm">Update Password</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
        </>
    );
}